<template>
  <div
    style="background-color: #f1f4f8; height: auto; min-height: 100% !important"
  >
    <div class="d-flex align-center pt-4 mb-7 ml-5">
      <h1 class="font-weight-bold mr-4" style="font-size: 22px">Relatórios</h1>
      <div max-width="300">
        <v-autocomplete
          :items="reportsCategory"
          v-model="reportsSelected"
          item-value="id"
          item-text="name"
          label="Relatórios..."
          single-line
          rounded
          hide-details
          dense
          @change="changeTable"
          solo
          flat
        ></v-autocomplete>
      </div>
    </div>
    <ReportsHistory
      v-if="reportsSelected === 1"
      pageTitle="Relatórios gerais"
      :reports="reports"
      :loading="loading"
      :showDataTable="showDataTable"
      :period-selected="periodCompany"
      @reload:reports="getCompanyReports"
      @update:period-selected="
        handleUpdatePeriod('periodCompany', $event, getCompanyReports)
      "
    />
    <ReportsHistory
      v-if="reportsSelected === 2"
      pageTitle="Contas de usuário"
      :reports="reportsAccounts"
      :loading="loading"
      :showDataTable="showDataTable"
      :period-selected="periodAccounts"
      @reload:reports="getAccountsReports"
      @update:period-selected="
        handleUpdatePeriod('periodAccounts', $event, getAccountsReports)
      "
    />
    <ReportsHistory
      v-if="reportsSelected === 3"
      pageTitle="Assinaturas de e-mail"
      :reports="reportsSignatures"
      :loading="loading"
      :showDataTable="showDataTable"
      :period-selected="periodSignatures"
      @reload:reports="getSignatureEmailReports"
      @update:period-selected="
        handleUpdatePeriod('periodSignatures', $event, getSignatureEmailReports)
      "
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { subtractDays } from "@/helpers/services/utils";
import ReportsHistory from "@/components/logs/ReportsHistory.vue";
import {
  SUITE_DEV_PROJECT_NAME,
  SUITE_PROD_PROJECT_NAME,
} from "@/helpers/variables/backendConstants";

export default {
  name: "Reports",
  components: {
    ReportsHistory,
  },

  data() {
    return {
      reportsSelected: 1,
      reports: [],
      reportsAccounts: [],
      reportsSignatures: [],
      loading: false,
      pageHeight: 0,
      showDataTable: false,
      reportsCategory: [
        { name: "Gerais", id: 1 },
        { name: "Contas de usuário", id: 2 },
        { name: "Assinaturas de e-mail", id: 3 },
      ],
      periodCompany: {
        textKey: "common.past7Days",
        value: subtractDays(7),
      },
      periodAccounts: {
        textKey: "common.past7Days",
        value: subtractDays(7),
      },
      periodSignatures: {
        textKey: "common.past7Days",
        value: subtractDays(7),
      },
    };
  },
  computed: {
    ...mapGetters(["token", "clientToken", "isResellerPage", "company"]),
  },
  methods: {
    async changeTable() {
      if (!this.reports.length && this.reportsSelected === 1) {
        await this.getCompanyReports();
      } else if (!this.reportsAccounts.length && this.reportsSelected === 2) {
        await this.getAccountsReports();
      } else if (!this.reportsSignatures.length && this.reportsSelected === 3) {
        await this.getSignatureEmailReports();
      }
    },

    async getCompanyReports() {
      this.loading = true;

      let clientToken = "";
      if (this.isResellerPage && this.clientToken) {
        clientToken = `Bearer ${this.clientToken}`;
      }

      const from_date = this.periodCompany.value;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/history?from_date=${from_date}`;

      const config = {
        headers: {
          Authorization: clientToken ? clientToken : this.token,
        },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reports = data;
          this.reports.concat(data);
        })
        .catch((err) => {
          this.showSnackBarError = true;
          console.error("getCompanyReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    getAccountsReports() {
      this.loading = true;

      let companyKey = "";
      if (this.isResellerPage) {
        companyKey = this.$route.query.key;
      }

      const from_date = this.periodAccounts.value;
      const url = `${process.env.VUE_APP_BIG_QUERY_API}/get-logs?from_date=${from_date}`;

      const config = {
        params: {
          company_key: companyKey ? companyKey : this.company.key,
          query: "accounts",
          project_name:
            process.env.NODE_ENV !== "production"
              ? SUITE_DEV_PROJECT_NAME
              : SUITE_PROD_PROJECT_NAME,
        },
      };

      this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reportsAccounts = data;
        })
        .catch((err) => {
          this.setSnackBar({
            message: this.errorMessages.LOG_ERROR,
            color: "error",
            show: true,
          });
          console.error("getAccountsReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    async getSignatureEmailReports() {
      this.loading = true;

      let clientToken = "";
      if (this.isResellerPage && this.clientToken) {
        clientToken = `Bearer ${this.clientToken}`;
      }

      const from_date = this.periodSignatures.value;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/history/signature?from_date=${from_date}`;

      const config = {
        headers: {
          Authorization: clientToken ? clientToken : this.token,
        },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.reportsSignatures = data;
        })
        .catch((err) => {
          this.setSnackBar({
            message:
              "Erro ao recuperar os registros de assinatura, tente novamente.",
            color: "error",
            show: true,
          });
          console.error("getSignatureEmailReports()", err);
        })
        .finally(() => (this.loading = false));
    },

    handleResize() {
      this.pageHeight = window.innerHeight;
    },

    async handleUpdatePeriod(periodSelected, event, reportGetter) {
      this[periodSelected] = event;
      await reportGetter();
    },
  },
  async beforeMount() {
    if (!this.isResellerPage) {
      await this.getCompanyReports();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
